import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IActiveSubscription } from '../types/subscription';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const fetchActiveSubscriptions = createAsyncThunk<
  IActiveSubscription[],
  void,
  ThunkConfig<string>
>('subscription/fetchActiveSubscriptions', async (_, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<IActiveSubscription[]>(
      urls.subscription.getAllActiveSubscriptions,
    );

    if (!response.data) throw new Error('error fetchActiveSubscriptions');

    return response.data;
  } catch (error) {
    const customError = error as AxiosError<any>;
    return rejectWithValue(customError.message);
  }
});

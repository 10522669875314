import { useLocale, useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from './progressBarActiveSubscriptions.module.css';
import { IActiveSubscription } from '@/entities/Subscription';
import { InfoCircleOutlinedSVG } from '@/shared/assets/svg/Statuses';
import { classNames } from '@/shared/lib/classNames/classNames';
import { convertDateAndMonthToLocal } from '@/shared/lib/helpers/convertDateAndMonthToLocal';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Icon } from '@/shared/ui/Icon';
import { Tooltip } from '@/shared/ui/Popovers';
import { ProgressBar } from '@/shared/ui/ProgressBar/ProgressBar';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IProgressBarActiveSubscriptionsProps {
  className?: string;
  subscription: IActiveSubscription;
}

export const ProgressBarActiveSubscriptions: FC<IProgressBarActiveSubscriptionsProps> =
  memo(props => {
    const {
      className,
      subscription: {
        daysLeft,
        daysOverall,
        endDate,
        nextPaymentDate,
        nextPaymentPrice,
      },
    } = props;

    const lang = useLocale() as 'ru' | 'en';
    const { isMobile } = useDetectDevice();
    const t = useTranslations();

    const restDays = useMemo(() => daysLeft <= 5, [daysLeft]);

    const nextPayData = useMemo(
      () =>
        nextPaymentDate
          ? convertDateAndMonthToLocal(nextPaymentDate, lang)
          : convertDateAndMonthToLocal(endDate, lang),
      [lang, nextPaymentDate, endDate],
    );

    const restDaysText = useMemo(
      () => (
        <Text
          variant='body-s'
          align={isMobile ? 'end' : 'start'}
          className={classNames(
            '',
            {
              [cls['red-text']]: restDays,
              [cls['text-default']]: !restDays,
              [cls['full-text']]: isMobile,
            },
            [],
          )}
        >{`${daysLeft} ${t('days left')}`}</Text>
      ),
      [daysLeft, isMobile, restDays, t],
    );

    const tooltip = useMemo(
      () => (
        <Tooltip
          titleText={`${t('The next money debiting will take place')} ${nextPayData} ${t('With')} $${nextPaymentPrice}`}
          placement={isMobile ? 'top' : 'bottom'}
          textWidth='182px'
        >
          <Icon Svg={InfoCircleOutlinedSVG} width={24} height={24} />
        </Tooltip>
      ),
      [isMobile, nextPayData, nextPaymentPrice, t],
    );

    return (
      <VStack
        max
        gap='x-s'
        className={classNames(cls['wrapper-progress-bar'], {}, [className])}
      >
        <HStack max justify='between'>
          <HStack gap='x-x-s'>
            <Text variant='body-s'>{`${t('Active till')} ${nextPayData}`}</Text>
            {!!nextPaymentPrice && !isMobile && tooltip}
          </HStack>
          {!isMobile && restDaysText}
        </HStack>
        <ProgressBar daysLeft={daysLeft} daysOverall={daysOverall} />
        {!!isMobile && restDaysText}
      </VStack>
    );
  });

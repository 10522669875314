import ErrorCircleSVG from './ErrorCircle.svg';
import ErrorTriangleSVG from './ErrorTriangle.svg';
import InfoCircleOutlinedSVG from './InfoCircleOutlined.svg';
import SuccessCircleSVG from './SuccessCircle.svg';

export {
  ErrorCircleSVG,
  InfoCircleOutlinedSVG,
  ErrorTriangleSVG,
  SuccessCircleSVG,
};

import { useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from './subscriptionCard.module.css';
import { IActiveSubscription } from '../../model/types/subscription';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import { ProgressBarActiveSubscriptions } from '@/features/ProgressBarActiveSubscriptions';
import QRCode from '@/shared/assets/images/Desktop/QR-Code/QR Code.png';
import { Telegram24p } from '@/shared/assets/svg/SocialNetworks';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Icon } from '@/shared/ui/Icon';
import { Links } from '@/shared/ui/Links/Links';
import { OldOrNewPrice } from '@/shared/ui/OldOrNewPrice';
import { Pictures } from '@/shared/ui/Pictures';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface ISubscriptionCardProps {
  className?: string;
  subscription: IActiveSubscription;
}

export const SubscriptionCard: FC<ISubscriptionCardProps> = memo(props => {
  const { className, subscription } = props;
  const { isMobile } = useDetectDevice();
  const t = useTranslations();

  const { daysOverall, price, telegramLink, title } = subscription;

  const gapPrice = useMemo(() => (isMobile ? 's' : 'm'), [isMobile]);

  return (
    <VStack
      gap='l'
      className={classNames(cls['subscription-card'], {}, [className])}
    >
      <VStack max gap={gapPrice}>
        <HStack max justify='between'>
          <Text as='h4' variant='body-l-medium'>
            {title}
          </Text>
          <OldOrNewPrice newPrice={price} period={daysOverall} size='s' />
        </HStack>
        <ProgressBarActiveSubscriptions subscription={subscription} />
      </VStack>
      <div className={cls.border} />
      <HStack gap='s' className={cls.qr}>
        <Pictures srcImage={[{ media: 76, src: QRCode }]} />
        <VStack max gap='x-s'>
          <Text variant='body-s'>
            {t(
              'Scan the Qr-code with your camera and you will get to the Telegram bot or',
            )}
          </Text>
          <Links
            href={telegramLink}
            target='_blank'
            gap='x-x-s'
            className={cls.links}
          >
            <Icon Svg={Telegram24p} width={16} height={16} />
            <Text variant='body-s' wrap='wrap'>
              {t('Open Telegram bot')}
            </Text>
          </Links>
        </VStack>
      </HStack>
    </VStack>
  );
});

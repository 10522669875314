import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchActiveSubscriptions } from '../services/fetchActiveSubscriptions';
import { IActiveSubscription, SubscriptionSchema } from '../types/subscription';

const initialState: SubscriptionSchema = {
  isLoadingSubscription: false,
  subscriptions: [],
};

export const subscriptionSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchActiveSubscriptions.pending, state => {
        state.errorSubscription = undefined;
        state.isLoadingSubscription = true;
      })
      .addCase(
        fetchActiveSubscriptions.fulfilled,
        (state, action: PayloadAction<IActiveSubscription[]>) => {
          state.isLoadingSubscription = false;
          state.subscriptions = action.payload;
        },
      )
      .addCase(fetchActiveSubscriptions.rejected, (state, action) => {
        state.isLoadingSubscription = false;
        state.errorSubscription = action.payload;
      });
  },
  initialState,
  name: 'subscription',
  reducers: {
    setIsLoadingSubscription: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingSubscription = payload;
    },
  },
});

export const {
  actions: { setIsLoadingSubscription },
} = subscriptionSlice;

export const { reducer: subscriptionReducer } = subscriptionSlice;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { errorHandlerForSaga } from '@/shared/lib/ErrorHandlerForSaga/ErrorHandlerForSaga';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

interface IDisableDD {
  id: string;
  lang: string;
  product: string;
}

export const disableDD = createAsyncThunk<
  void,
  IDisableDD,
  ThunkConfig<string>
>('subscription/disableDD', async ({ id, lang, product }, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  const toastMsg =
    lang === 'ru'
      ? `Вы успешно отключили автоматическое списание за ${product} подписку.`
      : `You are successfully turned off auto renewal for ${product} subscription`;

  try {
    const response = await extra.api.post(urls.subscription.disableDD(id));

    showSnackbar(toastMsg, 'success', lang);

    if (!response) throw new Error();

    return response.data;
  } catch (error) {
    errorHandlerForSaga(error);
    return rejectWithValue('ERROR');
  }
});
